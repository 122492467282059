import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

import { getCurrentUser } from "../redux/modules/users/usersSelectors";
import ForbiddenPage from "../components/pages/ForbiddenPage/ForbiddenPage";
import LoginComponent from "../components/pages/LoginPage/LoginComponent";

const withAuthorization = () => (WrappedComponent, redirectUrl) => {
  const WithAuthorization = (props) => {
    const { user, ...other } = props;
    const location = useLocation();

    if (!user || user.authorization.expired) {
      const redirectPath = `${location.pathname}${location.search}`;
      return <LoginComponent redirectPath={redirectPath} />;
    } else if (user && !user.authorization.expired) {
      return <WrappedComponent {...other} />;
    } else if (redirectUrl) {
      return <Navigate to={redirectUrl} replace />;
    } else {
      return <ForbiddenPage />;
    }
  };

  WithAuthorization.propTypes = {
    user: PropTypes.object,
  };

  const mapStateToProps = (state) => ({
    user: getCurrentUser(state),
  });

  return connect(mapStateToProps)(WithAuthorization);
};

export default withAuthorization;
