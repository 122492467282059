import axios from "axios";
import { OidcManager } from "../oidc/userManager.ts";
import store from "../redux/store";

const oidcManager = new OidcManager(store);

export const apiClient = axios.create({});

apiClient.interceptors.request.use(async (config) => {
  const user = await oidcManager.getUser();
  if (user) {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${user.access_token}`,
      },
    };
  }
});
