import { apiClient } from "./index";

export const addSickList = async (userId, dateRange) => {
  return await apiClient.post("/api/sicklists", {
    userId: userId,
    startDate: dateRange[0].format("YYYY-MM-DD"),
    endDate: dateRange[1].format("YYYY-MM-DD"),
  });
};

export const deleteSickList = async (sickListId) => {
  return await apiClient.delete(`/api/sicklists/${sickListId}`);
};

export const updateSickList = async (sickListId, dateRange) => {
  return await apiClient.put(`/api/sicklists/${sickListId}`, {
    startDate: dateRange[0],
    endDate: dateRange[1],
  });
};
