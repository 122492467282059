import { apiClient } from "./index";
import { withAPIFormat } from "../components/helper/momentJsExtensions";

export const getTimeTrackWeek = async (firstDayOfWeekDate) => {
  return apiClient.get("/api/week", {
    params: {
      firstDayOfWeekDate: withAPIFormat(firstDayOfWeekDate),
    },
  });
};

export const getEmployeesTimeTrackWeek = async (userId, firstDayOfWeekDate) => {
  return await apiClient.get(`/api/employee/${userId}/week`, {
    params: {
      firstDayOfWeekDate: withAPIFormat(firstDayOfWeekDate),
    },
  });
};

export const sendUpdatedTimeTrackWeek = async (
  userActivities,
  tasks,
  timeTrackRecords
) => {
  return await apiClient.post("/api/week", {
    userActivities,
    tasks,
    timeTrackRecords,
  });
};

export const sendUpdatedEmployeesTimeTrackWeek = async (
  userId,
  userActivities,
  tasks,
  timeTrackRecords
) => {
  return apiClient.post(`/api/employee/${userId}/week`, {
    userActivities,
    tasks,
    timeTrackRecords,
  });
};
