import { apiClient } from "./index";

export const getProductionCalendarByUserId = async (userId) => {
  return apiClient.get(`/api/production-calendar/${userId}`);
};

export const setProductionCalendarByUserId = async (
  userId,
  productionCalendarCountry
) => {
  return apiClient.put(`/api/production-calendar/${userId}`, {
    country: productionCalendarCountry,
  });
};

export const getCurrentUserProductionCalendar = async () => {
  return apiClient.get("/api/production-calendar");
};

export const getProductionCalendarsList = async () => {
  return apiClient.get("/api/production-calendar/list");
};
