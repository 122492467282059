const initialState = {
  type: "idle", // 'idle' | 'loading' | 'authenticated' | 'error'
  user: null,
  error: null,
};

export const oidcReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case "SET_AUTH_STATE":
      return { ...state, ...actions.payload };
    default:
      return state;
  }
};
