import { apiClient } from "./index";
import { withAPIFormat } from "../components/helper/momentJsExtensions";

export const getMonthlyReport = async (
  firstDayOfMonth,
  name,
  pageNumber,
  pageSize
) => {
  return apiClient.get("/api/reports/monthly", {
    params: {
      firstDayOfMonth: withAPIFormat(firstDayOfMonth),
      name,
      pageNumber,
      pageSize,
    },
  });
};

export const getYearlyReport = async (year, name, pageNumber, pageSize) => {
  return apiClient.get("/api/reports/yearly", {
    params: {
      year,
      name,
      pageNumber,
      pageSize,
    },
  });
};

export const getMonthlyPdfReport = async (
  userId,
  firstDayOfMonth,
  activitiesIds
) => {
  return await apiClient({
    url: "/api/reports/monthly/pdf",
    data: {
      userId,
      firstDayOfPeriod: withAPIFormat(firstDayOfMonth),
      activitiesIds,
    },
    method: "POST",
    responseType: "blob",
  });
};

export const getWeeklyPdfReport = async (
  userId,
  firstDayOfWeek,
  activitiesIds
) => {
  return await apiClient({
    url: "/api/reports/weekly/pdf",
    data: {
      userId,
      firstDayOfPeriod: withAPIFormat(firstDayOfWeek),
      activitiesIds,
    },
    method: "POST",
    responseType: "blob",
  });
};

export const getUserMonthlyExcelReport = async (userId, firstDayOfMonth) => {
  return await apiClient.get(`/api/reports/${userId}/monthly/excel`, {
    params: {
      firstDayOfPeriod: withAPIFormat(firstDayOfMonth),
    },
    responseType: "blob",
  });
};

export const getUserYearlyExcelReport = async (userId, firstDayOfYear) => {
  return await apiClient.get(`/api/reports/${userId}/yearly/excel`, {
    params: {
      firstDayOfPeriod: withAPIFormat(firstDayOfYear),
    },
    responseType: "blob",
  });
};

export const getMonthlyExcelReport = async (firstDayOfMonth) => {
  return await apiClient.get(`/api/reports/monthly/excel`, {
    params: {
      firstDayOfPeriod: withAPIFormat(firstDayOfMonth),
    },
    responseType: "blob",
  });
};

export const getYearlyExcelReport = async (firstDayOfYear) => {
  return await apiClient.get(`/api/reports/yearly/excel`, {
    params: {
      firstDayOfPeriod: withAPIFormat(firstDayOfYear),
    },
    responseType: "blob",
  });
};
