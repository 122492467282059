import { apiClient } from "./index";
import { withAPIFormat } from "../components/helper/momentJsExtensions";

export const getVacationHistory = async (
  vacationType,
  pageNumber,
  pageSize,
  startDate,
  endDate
) => {
  return await apiClient.get("/api/vacation", {
    params: {
      vacationType,
      pageNumber,
      pageSize,
      startDate: withAPIFormat(startDate),
      endDate: withAPIFormat(endDate),
    },
  });
};
