import React, { useCallback, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';

import roleConstants from '../../../helper/roleConstants';
import { Badge, MenuItem, Select, Tooltip, Typography, tooltipClasses } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser, selectUserInfo } from '../../../../redux/modules/users/usersSelectors';
import { clearUserInfo, getUserById } from '../../../../redux/modules/users/usersActions';
import {
  getCurrentUserProductionCalendar,
  getProductionCalendarByUserId,
  getProductionCalendarsList,
  setProductionCalendarByUserId,
} from '../../../../redux/modules/productionCalendar/productionCalendarActions';
import {
  productionCalendarCountrySelector,
  productionCalendarsListSelector,
} from '../../../../redux/modules/productionCalendar/productionCalendarSelectors';
import ConfirmationModal from '../../../common/ConfirmationModal/ConfirmationModal';

const Root = styled('div')(({ theme }) => ({
  width: '95%',
  marginRight: 'auto',
  marginLeft: 'auto',
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
}));

const StyledTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} arrow />)(
  ({ theme }) => {
    const color = theme.palette.secondary.light;
    return {
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: color,
        fontSize: '12px',
      },
      [`& .${tooltipClasses.arrow}`]: {
        color: color,
      },
    };
  }
);

const ProductionCalendar = ({ params }) => {
  const dispatch = useDispatch();
  const [productionCalendarValue, setProductionCalendarValue] = useState();
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const productionCalendarCountry = useSelector(productionCalendarCountrySelector);
  const productionCalendarsList = useSelector(productionCalendarsListSelector);
  const currentUser = useSelector(getCurrentUser);
  const userInfo = useSelector(selectUserInfo);

  const currentUserCanChangeProductionCalendar = currentUser.role.includes(roleConstants.superManager);

  const selectedUserId = params.id || currentUser.id;

  useEffect(() => {
    if (currentUserCanChangeProductionCalendar) {
      dispatch(getProductionCalendarsList());
    }

    if (selectedUserId !== currentUser.id) {
      dispatch(getUserById(selectedUserId));
      dispatch(getProductionCalendarByUserId(selectedUserId));
    } else {
      dispatch(getCurrentUserProductionCalendar());
    }

    return () => {
      dispatch(clearUserInfo());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserId, currentUser, dispatch]);

  const handleProductionCalendarChange = (value) => {
    setProductionCalendarValue(value);
    setConfirmationModalOpen(true);
  };

  const handleSubmit = useCallback(() => {
    dispatch(setProductionCalendarByUserId(selectedUserId, productionCalendarValue));
    setConfirmationModalOpen(false);
  }, [dispatch, productionCalendarValue, selectedUserId]);

  const handleClose = useCallback(() => {
    setConfirmationModalOpen(false);
  }, []);

  return (
    <Root onSubmit={handleSubmit} noValidate>
      <Typography type="subtitle1">{userInfo ? userInfo.name : 'Your'} production calendar is: </Typography>
      {currentUserCanChangeProductionCalendar ? (
        <>
          <Select
            label="Production Calendar"
            value={productionCalendarCountry}
            onChange={(e) => handleProductionCalendarChange(e.target.value)}
            variant="standard"
            color="secondary"
          >
            <MenuItem key="none" value="" disabled>
              None
            </MenuItem>
            {productionCalendarsList.map((x) => (
              <MenuItem key={x.country} value={x.country}>
                {x.country}
              </MenuItem>
            ))}
          </Select>
        </>
      ) : (
        <>
          <StyledTooltip placement="top" title="Please contact the accounting department to change production calendar">
            <Badge color="secondary" variant="dot">
              <Typography type="subtitle1">
                {productionCalendarCountry && productionCalendarCountry.length ? productionCalendarCountry : 'None'}
              </Typography>
            </Badge>
          </StyledTooltip>
        </>
      )}
      <ConfirmationModal
        isOpen={confirmationModalOpen}
        confirmButtonName={'Change'}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        modalTitle={`Are you sure that you want to change production calendar from ${
          productionCalendarCountry || productionCalendarCountry.length ? productionCalendarCountry : '"None"'
        } to "${productionCalendarValue}?"`}
      />
    </Root>
  );
};

export default ProductionCalendar;
