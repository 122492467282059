import React, { useEffect } from "react";
import FullScreenLoader from "../../common/FullScreenLoader/FullScreenLoader";
import { useAuth } from "../../../hooks/useAuth";

const LoginComponent = () => {
  const authManager = useAuth();

  useEffect(() => {
    authManager && authManager.onLoad();
  }, [authManager]);
  return <FullScreenLoader />;
};

export default LoginComponent;
