import React, { useState } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useWidth from "../../../../hooks/useWidth";
import hamburgerIcon from "../../../../assets/icons-svg/hamburger-icon.svg";
import NavigationSidebar from "../../NavigationSidebarWrapper/NavigationSidebar/NavigationSidebar";
import { useDispatch } from "react-redux";
import { showMyManagersModal } from "../../../../redux/modules/modals/modalsActions";
import { useAuth } from "../../../../hooks/useAuth";

const PREFIX = "HeaderBar";

const classes = {
  header: `${PREFIX}-header`,
  contentWrapper: `${PREFIX}-contentWrapper`,
  pageTitle: `${PREFIX}-pageTitle`,
  userInfo: `${PREFIX}-userInfo`,
  selectedUserName: `${PREFIX}-selectedUserName`,
  hamburgerButton: `${PREFIX}-hamburgerButton`,
  menuButton: `${PREFIX}-menuButton`,
  backButton: `${PREFIX}-backButton`,
  userInfoContainer: `${PREFIX}-userInfoContainer`,
  pageTitleContainer: `${PREFIX}-pageTitleContainer`,
};

const Root = styled("header")(({ theme }) => ({
  minHeight: "58px",
  background: theme.palette.white,

  alignItems: "center",
  boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.05)",
  zIndex: 2,

  [`& .${classes.contentWrapper}`]: {
    width: "95%",
    height: "58px",
    marginRight: "auto",
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  [`& .${classes.pageTitleContainer}`]: {
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.pageTitle}`]: {
    fontSize: "24px",
    fontWeight: "bold",
    color: theme.palette.grey.dark,
  },
  [`& .${classes.userInfoContainer}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },

  [`& .${classes.userInfo}`]: {
    fontWeight: 600,
    color: theme.palette.secondary.light,
  },

  [`& .${classes.selectedUserName}`]: {
    color: theme.palette.grey.dark,
    flexGrow: 1,
    paddingLeft: "2rem",
    fontWeight: 600,
  },

  [`& .${classes.hamburgerButton}`]: {
    height: "30px",
    width: "30px",
    backgroundImage: `url(${hamburgerIcon})`,
    cursor: "pointer",
    margin: "10px",
  },

  [`& .${classes.menuButton}`]: {
    textTransform: "none",
  },

  [`& .${classes.backButton}`]: {
    marginRight: "10px",
  },
}));

const HeaderBar = (props) => {
  const { pageTitle, user, withBackButton } = props;
  const width = useWidth();
  const [menuAnchor, setMenuAnchor] = useState(null);
  const menuIsOpen = Boolean(menuAnchor);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const displayUsername = user ? `${user.firstName} ${user.lastName}` : "";

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authManager = useAuth();

  const handleClickOutside = () => {
    if (isSidebarOpen) {
      setIsSidebarOpen(false);
    }
  };

  const toggleSidebarOpen = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  const handleMenuOpen = (e) => {
    setMenuAnchor(e.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleMyManagersClick = () => {
    dispatch(showMyManagersModal());
  };

  const handleChangePassword = () => {
    const url = `${process.env.REACT_APP_AUTHORITY_URL}/account/changepassword?returnUrl=${window.location.href}`;
    window.location.assign(url);
  };

  const handleProfileClick = () => {
    const url = `${process.env.REACT_APP_AUTHORITY_URL}/account/profile?returnUrl=${window.location.href}`;
    window.location.assign(url);
  };

  const handleVacationHistory = () => {
    navigate("/vacation");
  };

  const handleSignOut = async (event) => {
    event.preventDefault();
    await authManager.logout();
  };

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  return (
    <Root>
      <div className={classes.contentWrapper}>
        {(width === "sm" || width === "xs") && (
          <div
            className={classes.hamburgerButton}
            onClick={toggleSidebarOpen}
          />
        )}
        <div className={classes.pageTitleContainer}>
          {withBackButton && (
            <Button
              className={classes.backButton}
              onClick={handleBackButtonClick}
            >
              <ArrowBackIcon fontSize="large" color="secondary" />
            </Button>
          )}
          <Typography className={classes.pageTitle}>{pageTitle}</Typography>
        </div>
        <div className={classes.userInfoContainer}>
          <Button className={classes.menuButton} onClick={handleMenuOpen}>
            <Typography className={classes.userInfo}>
              {displayUsername}
            </Typography>
          </Button>
          <Typography className={classes.selectedUserName}>
            [{user.userName}]
          </Typography>
        </div>
        <Menu
          anchorEl={menuAnchor}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={menuIsOpen}
          onClose={handleMenuClose}
          onClick={handleMenuClose}
        >
          <MenuItem onClick={handleVacationHistory}>Vacation History</MenuItem>
          {/* Temporary remove Profile options */}
          {/* <MenuItem onClick={handleProfileClick}>Profile</MenuItem> */}
          {/* <MenuItem onClick={handleChangePassword}>Change Password</MenuItem> */}
          <MenuItem onClick={handleMyManagersClick}>My Managers</MenuItem>
          <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
        </Menu>
      </div>
      {ReactDOM.createPortal(
        isSidebarOpen && (
          <NavigationSidebar
            isSidebarOpen={isSidebarOpen}
            handleBlur={handleClickOutside}
          />
        ),
        document.getElementById("root")
      )}
    </Root>
  );
};

HeaderBar.propTypes = {
  user: PropTypes.object,
  pageTitle: PropTypes.string,
};

export default HeaderBar;
